<template>
  <b-modal centered text id="confirmCartModal" title="" hide-footer>
    <div class="text-center mtb30 cart-popup">
      <img src="/assets/images/banner/thumbs-up.svg" />
      <div class="modal-head modal-head-success">Successfully Added!</div>
      <!-- <div class="button-bar-confirm">
            <button class="btn cart-btn" @click="$bvModal.hide('confirmRemoveModal')">Cancel</button>
            <button class="btn secondary-btn" @click="removeFromCart()">Remove</button>
          </div> -->
      <div class="button-bar-confirm">
        <button
          class="btn cart-btn"
          @click="
            $bvModal.hide('confirmCartModal');
            goToCategory();
          "
        >
          Continue Shopping
        </button>
        <button @click="goToCart()" class="btn secondary-btn">Go To Cart</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  methods: {
    goToCart() {
      this.$router.push({ name: "cart" }).catch(() => {});
    },
    goToCategory() {
      this.$router.push({ name: "category" }).catch(() => {});
    },
  },
};
</script>
